export enum WhereFilter {
  "<" = "<",
  "<=" = "<=",
  "==" = "==",
  "!=" = "!=",
  ">=" = ">=",
  ">" = ">",
  "array-contains" = "array-contains",
  "in" = "in",
  "array-contains-any" = "array-contains-any",
  "not-in" = "not-in",
}
