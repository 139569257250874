import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_DOCUMENTSNAPSHOT } from "../../types/firbase.types";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import Skeleton from "@mui/material/Skeleton";

import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import { useAtom, useSetAtom } from "jotai";
import { ROLES_ATOMS } from "./RolesPage.atoms";
import {
  PRE_DEFINED_ROLES_PERMISSIONS,
  PreDefinedRolesPermissions,
} from "./CreateRoleModal/RolesPermissionsStep/RolesPermissionsStep.types";
import { getFirebaseDataCollection } from "../../hooks/firebase/getFirebaseDataCollection";

interface RoleData {
  [key: string]: {
    addEvent: boolean;
    addPost: boolean;
    deleteEvent: boolean;
    deletePost: boolean;
    editEvent: boolean;
    editPost: boolean;
    viewProfiles: boolean;
    createPaymentRequest: boolean;
  };
}

export const StyledLogoImage = styled.img({
  display: "block",
  width: 35,
});

export const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const StyledClientName = styled.div({
  alignSelf: "center",
  fontSize: 14,
  color: APP_COMMON_COLORS.yourzown.colorPrimary,
});

const updatePermissions = (
  roleName: string,
  clientRolesData: RoleData
): PreDefinedRolesPermissions => {
  const updatedPermissions: PreDefinedRolesPermissions = {
    addEvent: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.addEvent,
      isChecked: Boolean(clientRolesData[roleName]?.addEvent),
    },
    editEvent: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.editEvent,
      isChecked: Boolean(clientRolesData[roleName]?.editEvent),
    },
    deleteEvent: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.deleteEvent,
      isChecked: Boolean(clientRolesData[roleName]?.deleteEvent),
    },
    addPost: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.addPost,
      isChecked: Boolean(clientRolesData[roleName]?.addPost),
    },
    editPost: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.editPost,
      isChecked: Boolean(clientRolesData[roleName]?.editPost),
    },
    deletePost: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.deletePost,
      isChecked: Boolean(clientRolesData[roleName]?.deletePost),
    },
    viewProfiles: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.viewProfiles,
      isChecked: Boolean(clientRolesData[roleName]?.viewProfiles),
    },
    createPaymentRequest: {
      ...PRE_DEFINED_ROLES_PERMISSIONS.createPaymentRequest,
      isChecked: Boolean(clientRolesData[roleName]?.createPaymentRequest),
    },
  };

  return updatedPermissions;
};

const getRolesMemberCount = async (clientId: string) => {
  const response = await firebaseHttpsCallable(
    FIRESTORE_FUNCTIONS.getRolesMemberCount
  )({
    clientId: clientId,
  });

  return response.data as { [key: string]: number };
};

export const useFirebaseStreamRoles = (clientId: string) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [rolesMemberCount, setRolesMemberCount] = useState<{
    [key: string]: number;
  }>();
  const setIsModalOpen = useSetAtom(ROLES_ATOMS.isModalOpen);
  const [isEditMode, setIsEditMod] = useAtom(ROLES_ATOMS.isEditMode);
  const [roleName, setRoleName] = useAtom(ROLES_ATOMS.roleName);
  const selectedPermissions = useSetAtom(ROLES_ATOMS.selectedPermissions);
  const setAddedFields = useSetAtom(ROLES_ATOMS.addedFields);

  const handleEditRole = (_roleName: string, clientRolesData: RoleData) => {
    setIsModalOpen(true);
    setIsEditMod(true);
    setRoleName(_roleName);
    selectedPermissions({ ...updatePermissions(_roleName, clientRolesData) });
  };

  useEffect(() => {
    getFirebaseDataCollection(COLLECTIONS_TYPE.additionalProfileInfo).then(
      (res) => {
        const _addedFields: [] =
          res && res[clientId] && res[clientId][roleName];

        if (Object.values(_addedFields || [])?.length > 0) {
          setAddedFields(Object.values(_addedFields));
        } else {
          setAddedFields([]);
        }
      }
    );
  }, [roleName]);

  useEffect(() => {
    const setRolesCount = async () => {
      setRolesMemberCount(await getRolesMemberCount(clientId));
    };
    setRolesCount();
  }, []);

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.roles)
      .doc(clientId)
      .onSnapshot(async (docSnapshot: FB_DOCUMENTSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        const clientRolesData = docSnapshot.data() as RoleData;

        const clientRolesSortedKeys = Object.keys(
          (clientRolesData as { [key: string]: any }) || {}
        ).sort();

        clientRolesSortedKeys.forEach((_roleName: string) => {
          _row = {
            id: _roleName,
            cells: [
              _roleName,
              rolesMemberCount ? (
                String(rolesMemberCount[_roleName] || 0)
              ) : (
                <Skeleton style={{ width: 20 }} />
              ),
              <StyledActions>
                <AppSelectButton
                  title={<ThreeDotsIcon />}
                  buttonsList={["Edit"]}
                  buttonActions={[
                    () => handleEditRole(_roleName, clientRolesData),
                  ]}
                />
              </StyledActions>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [fetched, rolesMemberCount]);

  return fetched ? rows : null;
};
