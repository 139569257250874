import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import {
  ADD_EDIT_CLIENT_MODAL_MODE,
  CLIENT_STATE,
} from "../../types/client/client.types";
import styled from "styled-components";
import { MEMBERS } from "../../types/members/members.types";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { RecycleBinIcon } from "../../assets/icons/RecycleBinIcon";
import { Divider, Skeleton } from "@mui/material";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import { NoLogoIcon } from "../../assets/icons/NoLogoIcon";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import { useDispatch } from "react-redux";
import { setAddEditClientModalData } from "../../redux/client/clientSlice";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { ChildrenDTO } from "../MembersPage/MembersPage.types";

export const StyledLogoImage = styled.img({
  display: "block",
  width: 35,
});

export const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const StyledClientName = styled.div({
  alignSelf: "center",
  fontSize: 14,
  color: APP_COMMON_COLORS.yourzown.colorPrimary,
});

const getMembersCount = (membersData: MEMBERS[], clientId: string) => {
  let memberCount = 0;
  membersData?.forEach((member) => {
    if (member?.clientsIds && member?.clientsIds[clientId]) {
      memberCount = memberCount + 1;
    }
  });
  return memberCount;
};

const getChildrenCount = (
  allClientsChildrenData: ChildrenDTO[],
  clientId: string
) => {
  let childrenCount = 0;
  allClientsChildrenData?.forEach((child) => {
    if (child?.clientId && child?.clientId === clientId) {
      childrenCount = childrenCount + 1;
    }
  });
  return childrenCount;
};

export const useFirebaseStreamClients = (
  membersData: {
    [x: string]: MEMBERS;
  },
  allClientsChildrenData: {
    [x: string]: ChildrenDTO;
  }
) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);

  const handleEditClient = (clientData: CLIENT_STATE, docId: string) => {
    dispatch(
      setAddEditClientModalData({
        isOpen: true,
        mode: ADD_EDIT_CLIENT_MODAL_MODE.EDIT_CLIENT,
        docId,
        clientData,
      })
    );
  };

  const handleReSendClientInvite = async (clientData: CLIENT_STATE) => {
    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.sendInviteClient)({
      client: clientData,
    })
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Success", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something went wrong [2]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something went wrong [3]", APP_TOASTIFY.ERROR);
      });
  };

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.clients)
      .onSnapshot((querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        querySnapshot.forEach((doc) => {
          const docId = doc.id;
          const { branding, clientAdmin, clientId, clientName } =
            doc?.data() as CLIENT_STATE;

          _row = {
            id: `${clientName} ${clientId}`,
            cells: [
              <div style={{ display: "flex", gap: 29 }}>
                {branding?.logoUrl ? (
                  <div
                    style={{
                      width: 35,
                      height: 35,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StyledLogoImage src={branding?.logoUrl} />
                  </div>
                ) : (
                  <div style={{ width: 35, height: 35 }}>
                    <NoLogoIcon />
                  </div>
                )}
                <StyledClientName>{clientName}</StyledClientName>
              </div>,
              membersData ? (
                String(
                  Number(
                    getMembersCount(
                      Object.values(membersData || []),
                      String(clientId)
                    ) +
                      Number(
                        getChildrenCount(
                          Object.values(allClientsChildrenData || []),
                          String(clientId)
                        )
                      )
                  )
                )
              ) : (
                <Skeleton width={30} />
              ),
              <StyledActions>
                {/*
                   TODO: put back when functionality is ready for delete button
                  <RecycleBinIcon />
                  <Divider orientation="vertical" style={{ height: 22 }} /> 
                  */}
                <AppSelectButton
                  title={<ThreeDotsIcon style={{ paddingRight: 12 }} />}
                  buttonsList={["Edit", "Re-invite"]}
                  buttonActions={[
                    () => handleEditClient(doc?.data() as CLIENT_STATE, docId),
                    () => handleReSendClientInvite(doc?.data() as CLIENT_STATE),
                  ]}
                />
              </StyledActions>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });

    return () => unsubscribe();
  }, [fetched, membersData]);

  return fetched ? rows : null;
};
