import { Box, CircularProgress, Tab, Tabs, useTheme } from "@mui/material";
import { TabPanel } from "../../../../components/shared/AppModalDesktopSteps/TabPanel/TabPanel";
import { AppSwitch } from "../../../../components/core/AppSwitch/AppSwitch";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { SubGroup } from "../../MembersPage.types";
import {
  StyledGroupsTabContainer,
  StyledMemberTreeContainer,
} from "./GroupsTab.styles";
import { useEffect, useState } from "react";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../../../constants/firebase.constants";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import {
  mapFlatGroupAndSubGroupsIds,
  getIsKeyContactToggleChecked,
  mapSubGroupsContactData,
  mapFlatGroupAndSubGroupsNamesAndIdsForRole,
  mapMemberGroupsFromArrayToObject,
} from "./GroupsTab.helper";
import { INVITE_MEMBER_DTO } from "../../../../types/members/members.types";
import { StyledTabContainer } from "../../MembersPage.styles";
import { capitalizeFirstChar } from "../../../../helpers/common";
import { useAtom } from "jotai";
import { MEMBERS_ATOMS } from "../../MembersPage.Atoms";
import { StyledAppModalFooterButtonContainer } from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { StyledSwitchAndTextContainer } from "../../../../components/shared/StyledComponents/shared-styled-components";
import { getFirebaseDataByColDocColKeyVal } from "../../../../hooks/firebase/getFirebaseDataByColDocColKeyVal";
import { StyledDivider } from "../../../../components/shared/StyledComponents/StyledDivider";
import { InlineErrorMessage } from "../InlineErrorMessage";
import { MemberSubGroupsTreeWithCheckboxes } from "../MemberSubGroupsTreeWithCheckboxes/MemberSubGroupsTreeWithCheckboxes";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { sortTreesRowsByName } from "../tabs.helper";

const nonAdminNonGuardianErrorMessage =
  "For non-admin and non-guardian users, please create a custom role first and then assign the member to an existing group.";
interface GroupsTabTabProps {
  outerTabValue: number;
  tabindex: number;
  addMemberTabs: string[];
  innerTabsValue: number;
  handleInnerTabChange: (newValue: number) => void;
  setIsModalClose: () => void;
  isEditMode: boolean;
}

export const GroupsTab = ({
  tabindex,
  outerTabValue,
  innerTabsValue,
  handleInnerTabChange,
  setIsModalClose,
  isEditMode,
}: GroupsTabTabProps) => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rowsFilter, setRowsFilter] = useState<{ [role: string]: string }>({});
  const [isGroupsLoading, setIsGroupsLoading] = useAtom(
    MEMBERS_ATOMS.isGroupsLoading
  );
  const [rolesAndSubGroups, setRolesAndSubGroups] = useAtom(
    MEMBERS_ATOMS.rolesAndSubGroups
  );

  const [currentActiveRole, setCurrentActiveRole] = useAtom(
    MEMBERS_ATOMS.currentActiveRole
  );

  const [clientGroupsAndSubGroups, setClientGroupsAndSubGroups] = useAtom(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );

  const [subGroupsContact, setSubGroupsContact] = useAtom(
    MEMBERS_ATOMS.subGroupsContact
  );

  const [addMemberFormValues, setAddMemebrsformValues] = useAtom(
    MEMBERS_ATOMS.addMemberFormValues
  );

  const { branding, clientId, clientName } = useSelector(getClientSelector);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const flatGroupAndSubGroupsNamesAndIds = mapFlatGroupAndSubGroupsIds(
      addMemberFormValues.roles && addMemberFormValues.roles[String(clientId)]
    );

    if (
      addMemberFormValues?.children?.length < 1 &&
      !addMemberFormValues?.isClientAdmin[String(clientId)] &&
      Object.keys(flatGroupAndSubGroupsNamesAndIds).length < 1
    ) {
      toast(nonAdminNonGuardianErrorMessage, APP_TOASTIFY.ERROR);
      setIsSubmitting(false);
      return;
    }

    if (Object.keys(flatGroupAndSubGroupsNamesAndIds).length < 1) {
      delete addMemberFormValues.roles;
    }

    const responseBody: INVITE_MEMBER_DTO = {
      ...addMemberFormValues,
      email: addMemberFormValues.email.toLowerCase(),
      firstName: capitalizeFirstChar(addMemberFormValues.firstName),
      lastName: capitalizeFirstChar(addMemberFormValues.lastName),
      roles: {
        ...(addMemberFormValues.roles
          ? addMemberFormValues.roles[String(clientId)]
          : {}),
      },
      client: {
        branding,
        clientId,
        clientName,
      },
      children: addMemberFormValues?.children?.map?.(
        ({ firstName, lastName, ...rest }) => ({
          ...rest,
          firstName: capitalizeFirstChar(firstName),
          lastName: capitalizeFirstChar(lastName),
        })
      ),
      subGroupsContact: subGroupsContact,
    };

    Object.entries(responseBody.roles || {}).forEach(([role, value]) => {
      if (!Object.keys(value || {}).length && responseBody.roles) {
        delete responseBody.roles[role];
        if (clientId && responseBody.additionalInfo?.[clientId]) {
          delete responseBody?.additionalInfo?.[clientId]?.[role];
        }
      }
    });

    if (
      !Object.keys(responseBody.roles || {}).length &&
      clientId &&
      responseBody?.additionalInfo?.[clientId]
    )
      responseBody.additionalInfo[clientId] = {};

    await firebaseHttpsCallable(
      isEditMode
        ? FIRESTORE_FUNCTIONS.updateMember
        : FIRESTORE_FUNCTIONS.inviteMember
    )(responseBody)
      .then((res: any) => {
        if (res?.data?.result === "nok") {
          toast("Something went wrong [6]", APP_TOASTIFY.ERROR);
        } else {
          toast(
            isEditMode
              ? "Member Updated Successfully"
              : "Invite Sent Successfully!",
            APP_TOASTIFY.SUCCESS
          );
          setIsModalClose();
          /** work around to reset data in formik (especially subscription for children) */
          isEditMode && window.location.reload();
        }
      })
      .catch((err: any) => {
        toast("Something went wrong [7]", APP_TOASTIFY.ERROR);
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleResetAddAsKeyContact = (roleName: string) => {
    setSubGroupsContact({
      ...mapSubGroupsContactData({}, roleName, subGroupsContact, false),
    });
  };

  const mapMemberGroupsFromObjectToArray = (roleName: string) => {
    if (
      addMemberFormValues?.roles &&
      addMemberFormValues.roles[String(clientId)] &&
      addMemberFormValues.roles[String(clientId)][roleName]
    ) {
      const obj = addMemberFormValues.roles[String(clientId)][roleName];
      const arr = Object.keys(obj).filter((key) => obj[key] === true);
      return arr;
    }
    return [];
  };

  useEffect(() => {
    if (clientId) {
      setIsGroupsLoading(true);
      firebaseHttpsCallable(FIRESTORE_FUNCTIONS.getRolesAndSubGroups)({
        clientId: clientId,
      })
        .then((res: any) => {
          const data = res.data;

          setRolesAndSubGroups({
            ...data?.data?.roles?.reduce((a: any, b: any) => {
              return { ...a, [b]: data?.data.subGroups };
            }, {}),
          });
        })
        .catch(() => {})
        .finally(() => {
          setIsGroupsLoading(false);
        });
    } else {
      setIsGroupsLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    if (isEditMode && addMemberFormValues.uid) {
      const getSubgroupsContact = async () => {
        await getFirebaseDataByColDocColKeyVal(
          COLLECTIONS_TYPE.subGroupsContacts,
          String(clientId),
          COLLECTIONS_TYPE.clientSubGroupsContacts,
          "memberId",
          String(addMemberFormValues.uid)
        ).then((res) => {
          const values: any = Object.values(res || {});
          if (values?.length > 0) {
            delete values[0]?.memberId;
            delete values[0]?.docId;
            delete values[0]?.clientId;
            setSubGroupsContact(values[0]);
          }
        });
      };
      getSubgroupsContact();
    }
  }, [addMemberFormValues.uid]);

  useEffect(() => {
    if (!addMemberFormValues.children?.length) {
      handleInnerTabChange(1);
    } else {
      handleInnerTabChange(0);
    }
  }, [addMemberFormValues.children?.length]);

  if (isGroupsLoading || isSubmitting)
    return (
      <div>
        <CircularProgress
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "50%",
            left: "50%",
            position: "absolute",
          }}
        />
      </div>
    );

  return (
    <TabPanel value={outerTabValue} index={tabindex}>
      <StyledGroupsTabContainer>
        <StyledTabContainer>
          <StyledAppModalDesktopStepTitle style={{ marginBottom: 15 }}>
            Roles & groups
          </StyledAppModalDesktopStepTitle>
          <StyledSwitchAndTextContainer>
            <AppSwitch
              onChange={(e: boolean) => {
                setAddMemebrsformValues((prev) => ({
                  ...prev,
                  isClientAdmin: { [String(clientId)]: e },
                }));
              }}
              checked={addMemberFormValues?.isClientAdmin?.[String(clientId)]}
              label={"Assign as Admin"}
            />
          </StyledSwitchAndTextContainer>

          {addMemberFormValues?.children?.length < 1 &&
          !addMemberFormValues?.isClientAdmin[String(clientId)] &&
          (Object.keys(rolesAndSubGroups ?? {})?.length < 2 ||
            clientGroupsAndSubGroups?.length < 1) ? (
            <InlineErrorMessage message={nonAdminNonGuardianErrorMessage} />
          ) : (
            clientGroupsAndSubGroups?.length > 0 && (
              <>
                <Box
                  sx={{
                    maxWidth: { xs: 320, sm: "100%" },
                  }}
                >
                  <Tabs
                    sx={{
                      paddingBottom: 2.5,
                      "& .MuiTabs-flexContainer": {
                        gap: 1.25,
                      },
                      "& button": {
                        borderRadius: 2,
                        background: theme.palette.common.white,
                        fontWeight: 500,
                        fontSize: "12px",

                        border: `1px solid ${theme.palette.grey[100]}`,
                        minWidth: 90,
                        height: 40,
                        boxShadow: "0px 5px 7px 0px #0000001A",
                        color: theme.palette.primary.main,
                      },
                      "& .Mui-selected": {
                        background: theme.palette.primary.main,
                        color: `${theme.palette.common.white} !important`,
                        boxShadow: "none",
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: "transparent",
                      },
                    }}
                    value={innerTabsValue || 0}
                    onChange={(_, tabIndex) => handleInnerTabChange(tabIndex)}
                    variant="scrollable"
                    aria-label="scrollable auto tabs example"
                  >
                    {Object.keys(rolesAndSubGroups ?? {}).map(
                      (_roleName: string) => {
                        if (
                          _roleName === "Guardian" &&
                          !addMemberFormValues?.children?.length
                        ) {
                          return <></>;
                        }

                        return (
                          <Tab
                            key={_roleName}
                            label={_roleName}
                            hidden
                            onClick={() => setCurrentActiveRole(_roleName)}
                          />
                        );
                      }
                    )}
                  </Tabs>
                </Box>

                {Object.keys(rolesAndSubGroups ?? {}).map(
                  (roleName: string, index: number) =>
                    index === innerTabsValue && (
                      <div key={roleName}>
                        <StyledDivider style={{ marginBottom: 10 }} />

                        <StyledMemberTreeContainer>
                          {clientGroupsAndSubGroups?.map((subGroups) => {
                            return (
                              <MemberSubGroupsTreeWithCheckboxes
                                flatTree={subGroups}
                                isDisabled={roleName === "Guardian"}
                                checkedSubGroups={[
                                  ...mapMemberGroupsFromObjectToArray(roleName),
                                ]}
                                onChangeCheckedSubGroups={(
                                  _checkedSubGroups
                                ) => {
                                  setAddMemebrsformValues({
                                    ...addMemberFormValues,
                                    roles: {
                                      ...addMemberFormValues.roles,
                                      [String(clientId)]: {
                                        ...(addMemberFormValues.roles
                                          ? addMemberFormValues.roles[
                                              String(clientId)
                                            ]
                                          : {}),
                                        [roleName]: {
                                          ...mapMemberGroupsFromArrayToObject(
                                            _checkedSubGroups
                                          ),
                                        },
                                      },
                                    },
                                  });
                                  handleResetAddAsKeyContact(roleName);
                                }}
                              />
                            );
                          })}
                        </StyledMemberTreeContainer>

                        <AppSwitch
                          onChange={(_isKeyContactChecked) => {
                            const memberRoleGroupsAndSubGroups =
                              addMemberFormValues.roles &&
                              addMemberFormValues.roles[String(clientId)] &&
                              addMemberFormValues.roles[String(clientId)][
                                roleName
                              ];

                            setSubGroupsContact({
                              ...mapSubGroupsContactData(
                                memberRoleGroupsAndSubGroups,
                                roleName,
                                subGroupsContact,
                                _isKeyContactChecked
                              ),
                            });
                          }}
                          checked={getIsKeyContactToggleChecked(
                            roleName,
                            subGroupsContact
                          )}
                          label="Add as Key Contact"
                          labelStyles={{ fontSize: 14 }}
                          containerStyles={{ padding: 0 }}
                          isDisabled={
                            Object.keys(
                              mapFlatGroupAndSubGroupsNamesAndIdsForRole(
                                addMemberFormValues.roles &&
                                  addMemberFormValues.roles[String(clientId)] &&
                                  addMemberFormValues.roles[String(clientId)][
                                    roleName
                                  ]
                              )
                            ).length < 1
                          }
                        />
                      </div>
                    )
                )}
              </>
            )
          )}

          <StyledAppModalFooterButtonContainer style={{ bottom: 20 }}>
            <AppButton isLoading={isSubmitting} onClick={onSubmit}>
              {isEditMode ? "Save" : "Send Invite"}
            </AppButton>
          </StyledAppModalFooterButtonContainer>
        </StyledTabContainer>
      </StyledGroupsTabContainer>
    </TabPanel>
  );
};
