import { Formik, FormikErrors, FormikTouched } from "formik";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import * as Yup from "yup";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { StyledForm, StyledFormInputs } from "./NameAndTypeStep.styles";
import { StyledDivider } from "../../../../components/shared/StyledComponents/StyledDivider";
import { AppRadioButtonGroup } from "../../../../components/core/AppFormik/AppFormikField/AppRadioButton";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalSubmitButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { TEMPLATE_TYPE } from "../../../../types/templates/templates.types";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  TEMPLATES_ATOMS,
  TEMPLATE_STEPPER_TITLES,
} from "../AddEditTemplateModal.atoms";
import { COLLECTIONS_TYPE } from "../../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { useEffect, useState } from "react";
import { getUser } from "../../../../redux/user/userSlice";
import { getFirebaseDataCollectionDoc } from "../../../../hooks/firebase/getFirebaseDataCollectionDoc";

export interface NameAndTypeStepValues {
  templateName: string;
  templateType: TEMPLATE_TYPE | undefined;
}

const nameAndTypeStepSchema = Yup.object().shape({
  templateName: Yup.string().required("Required"),
  templateType: Yup.string().required("Required"),
});

export const NameAndTypeStep: React.FC = () => {
  const [templateName, setTemplateName] = useAtom(TEMPLATES_ATOMS.templateName);
  const [templateType, setTemplateType] = useAtom(TEMPLATES_ATOMS.templateType);
  const setCurrentModalStep = useSetAtom(TEMPLATES_ATOMS.currentModalStep);
  const setIsPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const setTemplateStepperTitles = useSetAtom(
    TEMPLATES_ATOMS.templateStepperTitles
  );
  const setRoles = useSetAtom(TEMPLATES_ATOMS.roles);
  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);
  const templateId = useAtomValue(TEMPLATES_ATOMS.templateId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);

  const handleOnSubmit = (values: NameAndTypeStepValues) => {
    setCurrentModalStep(1);
  };

  useEffect(() => {
    getFirebaseDataCollectionDoc(
      COLLECTIONS_TYPE.roles,
      String(client.clientId)
    ).then((data) => {
      console.log(data);
      const sortedData = Object.fromEntries(
        Object?.entries(data || {}).sort((a, b) => {
          return a[0]?.localeCompare(b[0]);
        })
      );
      setRoles(sortedData);
    });
  }, []);

  return (
    <StyleAppModalSingleStepContainer>
      <StyledAppModalDesktopStepTitle style={{ marginBottom: 60 }}>
        Template type & name
      </StyledAppModalDesktopStepTitle>

      <Formik
        enableReinitialize={true}
        initialValues={{
          templateName: templateName,
          templateType: templateType,
        }}
        validationSchema={nameAndTypeStepSchema}
        onSubmit={(values: NameAndTypeStepValues) => {
          handleOnSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          submitCount,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const _touched: FormikTouched<NameAndTypeStepValues> = touched;
          const _errors: FormikErrors<NameAndTypeStepValues> = errors;

          return (
            <StyledForm>
              <StyledFormInputs>
                <StyledDivider />
                <AppRadioButtonGroup
                  options={[
                    { label: "Event", value: TEMPLATE_TYPE.event },
                    {
                      label: "Performance Post",
                      value: TEMPLATE_TYPE.performancePost,
                    },
                    { label: "Quick Post", value: TEMPLATE_TYPE.quickPost },
                    {
                      label: "Form Post",
                      value: TEMPLATE_TYPE.formPost,
                    },
                  ]}
                  value={values.templateType}
                  onChangeValue={(e) => {
                    setFieldValue("templateType", e);
                    setTemplateType(e);
                    setTemplateStepperTitles(TEMPLATE_STEPPER_TITLES[e]);
                  }}
                  errorMessage={
                    submitCount > 0 && _errors.templateType
                      ? String(errors.templateType)
                      : undefined
                  }
                  customContainerStyles={{ marginBottom: 30 }}
                  isDisabled={isEditMode}
                />

                <AppFormikField
                  isRequired={true}
                  name="templateName"
                  value={values.templateName}
                  label="TEMPLATE NAME"
                  errorMessage={
                    _touched.templateName && _errors.templateName
                      ? String(errors.templateName)
                      : undefined
                  }
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("templateName", e.target.value);
                    setTemplateName(e.target.value);
                  }}
                  type="text"
                  containerstyle={{ marginBottom: 25 }}
                />
              </StyledFormInputs>

              <StyledAppModalSubmitButtonContainer>
                <AppButton isLoading={isSubmitting} onClick={handleSubmit}>
                  Continue
                </AppButton>
              </StyledAppModalSubmitButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </StyleAppModalSingleStepContainer>
  );
};
