import { useAtom } from "jotai";
import { AppSideMenuContainer } from "../../../../../components/shared/AppSideMenuContainer/AppSideMenuContainer";
import { MEMBERS_ATOMS } from "../../../MembersPage.Atoms";
import { useEffect } from "react";
import { GroupsSideMenuPermissions } from "./GroupsSideMenuPermissions/GroupsSideMenuPermissions";
import { GroupsSideMenuTemplates } from "./GroupsSideMenuTemplates/GroupsSideMenuTemplates";
import { StyledDashedDivider } from "../../../../../components/shared/StyledComponents/StyledDashedDivider";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";
import { StyledPrimaryColorText } from "../../../../../components/shared/StyledComponents/shared-styled-components";

export const StyledTitle = styled.div({
  color: APP_COMMON_COLORS.dark[700],
  fontWeight: 500,
  fontSize: 16,
  marginBottom: 12,
  textTransform: "uppercase",
});

export const GroupsSideMenu = () => {
  const [currentActiveRole, setCurrentActiveRole] = useAtom(
    MEMBERS_ATOMS.currentActiveRole
  );

  const [rolesAndSubGroups, setRolesAndSubGroups] = useAtom(
    MEMBERS_ATOMS.rolesAndSubGroups
  );

  const [addMemberFormValues, setAddMemberFormValues] = useAtom(
    MEMBERS_ATOMS.addMemberFormValues
  );

  useEffect(() => {
    if (addMemberFormValues?.children?.length > 0) {
      setCurrentActiveRole("Guardian");
      return;
    }

    if (
      addMemberFormValues?.children?.length < 1 &&
      Object.keys(rolesAndSubGroups ?? {})[0] !== "Guardian"
    ) {
      setCurrentActiveRole(Object.keys(rolesAndSubGroups ?? {})[0]);
      return;
    }

    setCurrentActiveRole(Object.keys(rolesAndSubGroups ?? {})[1]);
    return;
  }, [rolesAndSubGroups, addMemberFormValues?.children]);

  if (!currentActiveRole) {
    return <div></div>;
  }

  return (
    <AppSideMenuContainer hideInMobile={true}>
      <StyledTitle>
        <StyledPrimaryColorText>{currentActiveRole}</StyledPrimaryColorText>
      </StyledTitle>

      <StyledDashedDivider style={{ marginBottom: 16 }} />

      <GroupsSideMenuPermissions />

      <StyledDashedDivider style={{ marginBlock: 16 }} />

      <GroupsSideMenuTemplates />
    </AppSideMenuContainer>
  );
};
