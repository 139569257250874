import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import {
  StyledForm,
  StyledInfoBody,
  StyledInfoTitle,
  StyledPermissionsInfos,
  StyledPermissionsInfosContainer,
  StyledPermissionsStepContainer,
} from "./PermissionsStep.styles";
import { StyledDivider } from "../../../../components/shared/StyledComponents/StyledDivider";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalSubmitButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { TEMPLATES_ATOMS } from "../AddEditTemplateModal.atoms";
import {
  AppCheckboxesRows,
  CheckboxData,
} from "../../../../components/core/AppCheckboxesRows/AppCheckboxesRows";
import { useEffect, useState } from "react";
import { AppSideMenuContainer } from "../../../../components/shared/AppSideMenuContainer/AppSideMenuContainer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "styled-components";
import {
  ADD_QUICK_POST_TEMPLATE_DTO,
  QUICK_POST_TEMPLATE,
  TEMPLATE_TYPE,
} from "../../../../types/templates/templates.types";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { capitalizeFirstChar } from "../../../../helpers/common";
import { getUser } from "../../../../redux/user/userSlice";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { GENERAL_REPONSE } from "../../../../types/firbase.types";
import { StyledDashedDivider } from "../../../../components/shared/StyledComponents/StyledDashedDivider";

const getActionButtonLabel = (templateType: TEMPLATE_TYPE) => {
  if (templateType === TEMPLATE_TYPE.quickPost) {
    return "Publish";
  }
  return "Continue";
};

export const PermissionsStep = () => {
  const theme = useTheme();
  const setCurrentModalStep = useSetAtom(TEMPLATES_ATOMS.currentModalStep);
  const [usableBy, setUsableBy] = useAtom(TEMPLATES_ATOMS.usableBy);
  const [viewableBy, setViewableBy] = useAtom(TEMPLATES_ATOMS.viewableBy);
  const roles = useAtomValue(TEMPLATES_ATOMS.roles);
  const [templateType, setTemplateType] = useAtom(TEMPLATES_ATOMS.templateType);
  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useSelector(getClientSelector);
  const [templateName, setTemplateName] = useAtom(TEMPLATES_ATOMS.templateName);
  const setIsPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const user = useSelector(getUser);
  const templateId = useAtomValue(TEMPLATES_ATOMS.templateId);

  const handleAddGeneralPostTemplate = async () => {
    setIsSubmitting(true);
    const payload: ADD_QUICK_POST_TEMPLATE_DTO = {
      clientId: String(client.clientId),
      templateName: capitalizeFirstChar(templateName),
      templateType: templateType as TEMPLATE_TYPE.quickPost,
      updatedBy: String(user.uid),
      usableBy: [...usableBy],
      viewableBy: [...viewableBy],
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addQuickPostTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Quick post template added", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Error while adding quick post template [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast("Error while adding quick post template [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleEditQuickPostTemplate = async () => {
    setIsSubmitting(true);
    const payload: QUICK_POST_TEMPLATE = {
      clientId: String(client.clientId),
      templateName: capitalizeFirstChar(templateName)?.trim(),
      templateType: templateType as TEMPLATE_TYPE.quickPost,
      updatedBy: String(user.uid),
      templateId: templateId,
      usableBy: [...usableBy],
      viewableBy: [...viewableBy],
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editQuickPostTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_REPONSE;
        if (data.result === "ok") {
          toast("Quick post template updated", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Error while updating quick post template [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast(
          "Error while updating quick post template [2]",
          APP_TOASTIFY.ERROR
        )
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleSubmit = () => {
    if (
      templateType === TEMPLATE_TYPE.event ||
      templateType === TEMPLATE_TYPE.performancePost ||
      templateType === TEMPLATE_TYPE.formPost
    ) {
      setCurrentModalStep(2);
      return;
    }

    if (templateType === TEMPLATE_TYPE.quickPost) {
      if (isEditMode) {
        handleEditQuickPostTemplate();
      } else {
        handleAddGeneralPostTemplate();
      }
      return;
    }
  };

  const mapStringArrayToCheckboxes = (
    data: string[],
    selectedRoles: string[]
  ): CheckboxData[] => {
    return data.map((item) => {
      return {
        label: item,
        isChecked: selectedRoles.includes(item),
      };
    });
  };

  return (
    <StyledPermissionsStepContainer>
      <StyleAppModalSingleStepContainer
        $customStylesDesktop={{ paddingBottom: 0 }}
      >
        <StyledAppModalDesktopStepTitle style={{ marginBottom: 10 }}>
          Template permissions
        </StyledAppModalDesktopStepTitle>
        <StyledDivider style={{ marginBottom: 10 }} />

        <StyledForm>
          <AppCheckboxesRows
            title={"Can add"}
            checkboxes={mapStringArrayToCheckboxes(
              Object.keys(roles || []),
              usableBy
            )}
            onChangeCheckedSubGroups={(data: CheckboxData[]) => {
              const newUsableBy = data
                .filter((item) => item.isChecked)
                .map((item) => item.label);
              setUsableBy(newUsableBy);
            }}
            noOptionsMessage={
              <div style={{ marginBottom: 30 }}>
                You have no roles to select from
              </div>
            }
            containerStyles={{ marginBottom: 20 }}
          />

          <AppCheckboxesRows
            title={"Can view"}
            checkboxes={mapStringArrayToCheckboxes(
              Object.keys(roles || []),
              viewableBy
            )}
            onChangeCheckedSubGroups={(data: CheckboxData[]) => {
              const newViewableBy = data
                .filter((item) => item.isChecked)
                .map((item) => item.label);
              setViewableBy(newViewableBy);
            }}
            noOptionsMessage={
              <div style={{ marginBottom: 30 }}>
                You have no roles to select from
              </div>
            }
          />
        </StyledForm>
        <StyledAppModalSubmitButtonContainer style={{ paddingBottom: 20 }}>
          <AppButton isLoading={isSubmitting} onClick={() => handleSubmit()}>
            {getActionButtonLabel(templateType as TEMPLATE_TYPE)}
          </AppButton>
        </StyledAppModalSubmitButtonContainer>
      </StyleAppModalSingleStepContainer>

      <AppSideMenuContainer hideInMobile={true}>
        <StyledPermissionsInfosContainer>
          <InfoOutlinedIcon style={{ color: theme.colorPrimary }} />
          <StyledPermissionsInfos>
            <div>
              <StyledInfoTitle>Can add</StyledInfoTitle>
              <StyledInfoBody>
                Choose which roles can create posts with this template. Only the
                roles you select will see this template as an option when making
                a post.
              </StyledInfoBody>
            </div>
            <StyledDashedDivider />
            <div>
              <StyledInfoTitle>Can view</StyledInfoTitle>
              <StyledInfoBody>
                Choose who can see posts made with this template. Only the roles
                you select, within the group the post is created, will be able
                to view these type of posts.
              </StyledInfoBody>
            </div>
            <StyledDashedDivider />

            <div>
              <StyledInfoTitle>Requires add post permission</StyledInfoTitle>
              <StyledInfoBody>
                For someone to create posts with this template, their role needs
                to be on the “Can Add” list, and they must also have the “Add
                Post” permission turned on in the Roles settings.
              </StyledInfoBody>
            </div>
          </StyledPermissionsInfos>
        </StyledPermissionsInfosContainer>
      </AppSideMenuContainer>
    </StyledPermissionsStepContainer>
  );
};
