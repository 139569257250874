import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalStepsContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { NameAndTypeStep } from "./NameAndTypeStep/NameAndTypeStep";
import { HIDDEN_ELEMENT_STYLES } from "../../../styles/styles.constants";
import { EventAdditionalFieldsStep } from "./EventAdditionalFieldsStep/EventAdditionalFieldsStep";
import { StyledModalBackButton } from "../../../components/core/AppModal/AppModal.styles";
import { BackButtonIcon } from "../../../components/core/AppBurgerMenu/icons/BackButtonIcon";
import { TEMPLATE_TYPE } from "../../../types/templates/templates.types";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { useAtom, useAtomValue } from "jotai";
import { TEMPLATES_ATOMS } from "./AddEditTemplateModal.atoms";
import { PerformancePostFields } from "./PerformancePostFields/PerformancePostFields";
import { FormPostFieldsV2 } from "./FormPostFieldsV2/FormPostFieldsV2";
import { PermissionsStep } from "./PermissionsStep/PermissionsStep";

export const AddEditTemplateModal = () => {
  const [currentModalStep, setCurrentModalStep] = useAtom(
    TEMPLATES_ATOMS.currentModalStep
  );
  const templateType = useAtomValue(TEMPLATES_ATOMS.templateType);
  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);

  const templateStepperTitles = useAtomValue(
    TEMPLATES_ATOMS.templateStepperTitles
  );

  const getModalTitle = () => {
    return isEditMode ? "Edit template" : "Create template";
  };

  return (
    <StyledAppModalStepsContainer>
      <AppModalMobileSteps
        tabsLength={templateStepperTitles.length}
        tabValue={currentModalStep}
        titlesOfSteps={templateStepperTitles}
        customSmallTitle={getModalTitle().toUpperCase()}
        hideStepsTitle={true}
      />
      <AppModalDesktopSteps
        tabs={templateStepperTitles}
        tabsTitle={getModalTitle()}
        OnChangeTabChange={setCurrentModalStep}
        tabValue={currentModalStep}
      />

      {currentModalStep > 0 && (
        <StyledModalBackButton
          onClick={() => setCurrentModalStep(currentModalStep - 1)}
        >
          <BackButtonIcon />
        </StyledModalBackButton>
      )}

      <div style={{ ...(currentModalStep !== 0 && HIDDEN_ELEMENT_STYLES) }}>
        <NameAndTypeStep />
      </div>

      <div
        style={{
          width: "100%",
          ...((currentModalStep !== 1 ||
            templateType === TEMPLATE_TYPE.event) &&
            HIDDEN_ELEMENT_STYLES),
        }}
      >
        <PermissionsStep />
      </div>

      <div
        style={{
          width: "100%",
          ...((currentModalStep !== 1 ||
            templateType !== TEMPLATE_TYPE.event) &&
            HIDDEN_ELEMENT_STYLES),
        }}
      >
        <EventAdditionalFieldsStep />
      </div>

      <div
        style={{
          width: "100%",
          ...((currentModalStep !== 2 ||
            templateType !== TEMPLATE_TYPE.performancePost) &&
            HIDDEN_ELEMENT_STYLES),
        }}
      >
        <PerformancePostFields />
      </div>

      <div
        style={{
          width: "100%",
          ...((currentModalStep !== 2 ||
            templateType !== TEMPLATE_TYPE.formPost) &&
            HIDDEN_ELEMENT_STYLES),
        }}
      >
        <FormPostFieldsV2 />
      </div>
    </StyledAppModalStepsContainer>
  );
};
