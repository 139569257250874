import styled, { CSSObject } from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledAppHeaderWrapper = styled.div<{ $isActive?: boolean }>(
  ({ $isActive, theme }) => ({
    backgroundColor: theme.colorPrimary,
    "@media only screen and (min-width: 1200px)": {
      backgroundColor: APP_COMMON_COLORS.common.white,
    },
  })
);

export const StyledAppHeaderContainer = styled.div({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px 20px",
  height: 85,
  "@media only screen and (min-width: 1200px)": {
    justifyContent: "space-between",
    height: 99,
    borderBottom: `1px solid ${APP_COMMON_COLORS.dark[300]}`,
    margin: "0px 40px",
  },
});

export const StyledLogoContainer = styled.div({
  width: "auto",
  height: 55,
  "@media only screen and (min-width: 1200px)": {
    display: "none",
  },
});

export const StyledLogoImg = styled.img({
  width: "auto",
  height: 55,
});

export const StyledAppBurgerMenuContainer = styled.div({
  position: "absolute",
  top: 30,
  right: 0,
});

export const StyledAvatarContainer = styled.div({
  display: "none",

  "@media only screen and (min-width: 1200px)": {
    display: "block",
    position: "absolute",
    top: 30,
    right: 0,
  },
});

export const StyledGreetingMessageContainer = styled.div({
  display: "none",
  "@media only screen and (min-width: 1200px)": {
    display: "block",
  },
});
