import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { _fs } from "../../services/firebase/config";
import { APP_ROUTES } from "../../constants/routes.constant";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { AuthLayout } from "../../components/shared/AuthLayout/AuthLayout";
import { getUser } from "../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFirebaseDataByColKeyVal } from "../../hooks/firebase/getFirebaseDataByColKeyVal";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { CLIENT_STATE } from "../../types/client/client.types";
import { YourZownIcon } from "../../assets/icons/YourZownIcon";
import { setClientId } from "../../redux/client/clientSlice";

const StyledClientCard = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 10,
  borderRadius: 10,
  paddingInline: 25,
  paddingBlock: 10,
  marginBottom: 10,
  cursor: "pointer",
  border: "2px solid #dbdbdb",
  height: 40,
  "&:hover": {
    borderColor: APP_COMMON_COLORS.yourzown.colorPrimary,
    color: APP_COMMON_COLORS.yourzown.colorPrimary,
  },
});

const StyledSelectorContainer = styled.div({
  marginTop: 75,
  "@media (min-width: 1200px)": {
    marginTop: 30,
  },
});

export const StyledLogoImg = styled.img({
  width: 40,
  height: "auto",
});

const fetchFirebaseColors = async (clientId: string) => {
  try {
    const res = await getFirebaseDataByColKeyVal(
      COLLECTIONS_TYPE.clients,
      "clientId",
      clientId
    );

    const clientData = Object.values(res)[0] as CLIENT_STATE;
    return clientData;
  } catch (err) {
    console.log({ err });
    toast("Something went wrong [1]", APP_TOASTIFY.ERROR);
  }
};

export const ClientSelectorPage: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [clientAdmins, setClientAdmins] = useState([]);
  const navigate = useNavigate();

  const onSelectClient = (client: CLIENT_STATE) => {
    if (client.clientId) {
      dispatch(setClientId({ clientId: client.clientId }));
      localStorage.setItem("selectedClientId", client.clientId);
      navigate(APP_ROUTES.dashboard);
    }
  };

  const sortedCLients = clientAdmins.sort(
    (a: CLIENT_STATE, b: CLIENT_STATE) => {
      if ((a.clientName || "") < (b.clientName || "")) {
        return -1;
      }
      if ((a.clientName || "") > (b.clientName || "")) {
        return 1;
      }

      return 0;
    }
  );
  useEffect(() => {
    const userClients = Object.entries(user.isClientAdmin || {})?.filter(
      ([_clientId, isClientAdmin]) => isClientAdmin
    );
    Promise.all(userClients.map((client) => fetchFirebaseColors(client[0])))
      .then((res) => setClientAdmins(res as any))
      .catch((e) => console.log(e));
  }, [user]);

  return (
    <AuthLayout
      title="Welcome To"
      subtitle="YourZown"
      iconSubtitle="Select a client"
      children={
        <StyledSelectorContainer>
          {sortedCLients?.map((client: CLIENT_STATE) => (
            <StyledClientCard onClick={() => onSelectClient(client)}>
              {client?.branding?.logoUrl ? (
                <StyledLogoImg src={client?.branding.logoUrl} alt="logo" />
              ) : (
                <YourZownIcon width="40" height="auto" />
              )}
              <span>{client.clientName}</span>
            </StyledClientCard>
          ))}
        </StyledSelectorContainer>
      }
    />
  );
};
