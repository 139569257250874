import React, { useState } from "react";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalFooterButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { PermissionsValues } from "./RolesPermissionsStep.types";
import {
  StyledInputsContainer,
  StyledRolesPermissionsToggleRow,
  StyledRolesTogglesContainer,
} from "./RolesPermissionsStep.styles";
import { AppSwitch } from "../../../../components/core/AppSwitch/AppSwitch";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import {
  areAllPermissionsTogglesChecked,
  filterCheckedPermissions,
} from "./RolesPermissionsStep.helper";
import { SideInfoPermissionsInRole } from "./SideInfoPermissionsInRole";
import { AppSideMenuContainer } from "../../../../components/shared/AppSideMenuContainer/AppSideMenuContainer";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ROLES_ATOMS } from "../../RolesPage.atoms";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { toast } from "react-toastify";
import {
  ADD_ROLE_TO_CLIENT_DTO,
  EDIT_CLIENT_ROLE_DTO,
} from "../../../../types/roles/roles.types";
import { Tooltip } from "@mui/material";

export const RolesPermissions = () => {
  const roleName = useAtomValue(ROLES_ATOMS.roleName);
  const addedFields = useAtomValue(ROLES_ATOMS.addedFields);
  const [selectedPermissions, setSelectedPermissions] = useAtom(
    ROLES_ATOMS.selectedPermissions
  );
  const setIsPublished = useSetAtom(ROLES_ATOMS.isPublished);
  const isEditMode = useAtomValue(ROLES_ATOMS.isEditMode);

  const [isLoading, setIsLoading] = useState(false);
  const client = useSelector(getClientSelector);
  const isActiveAccount = client.paymentAccountStatus === "active";

  const handleToggleChange = (
    permissionValue: PermissionsValues,
    event: boolean
  ) => {
    setSelectedPermissions((prevState) => ({
      ...prevState,
      [permissionValue]: {
        ...prevState[permissionValue],
        isChecked: event,
      },
    }));
  };

  const handleAllTogglesChange = (event: boolean) => {
    Object.values(selectedPermissions).forEach((permission) => {
      if (
        permission.value === PermissionsValues.createPaymentRequest &&
        !isActiveAccount
      ) {
        return;
      }
      handleToggleChange(permission.value, event);
    });
  };

  const handleAddRole = async () => {
    const payload: ADD_ROLE_TO_CLIENT_DTO = {
      clientId: String(client.clientId),
      roleName,
      fields: addedFields,
      permissions: filterCheckedPermissions(selectedPermissions),
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addRoleToClient)(payload)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast(`${roleName} role added to client`, APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(`Error while adding ${roleName} role [1]`, APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast(`Error while adding ${roleName} role [2]`, APP_TOASTIFY.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditRole = async () => {
    const payload: EDIT_CLIENT_ROLE_DTO = {
      clientId: String(client.clientId),
      roleName,
      fields: addedFields,
      permissions: filterCheckedPermissions(selectedPermissions),
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editClientRole)(payload)
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast(`${roleName} role updated`, APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            `Error while updating ${roleName} role [1]`,
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast(`Error while updating ${roleName} role [2]`, APP_TOASTIFY.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePublish = async () => {
    setIsLoading(true);

    if (isEditMode) {
      handleEditRole();
    } else {
      handleAddRole();
    }
  };

  const getTooltipText = (value: PermissionsValues) => {
    if (value === PermissionsValues.createPaymentRequest && !isActiveAccount) {
      return "Payments need to be setup to enable this permission";
    }
    return "";
  };

  const isOptionDisabled = (value: PermissionsValues) => {
    if (value === PermissionsValues.createPaymentRequest && !isActiveAccount) {
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <StyleAppModalSingleStepContainer $customStylesMobile={{ width: "100%" }}>
        <StyledAppModalDesktopStepTitle style={{ marginBottom: 10 }}>
          Set permissions
        </StyledAppModalDesktopStepTitle>

        <StyledInputsContainer>
          <StyledRolesPermissionsToggleRow
            style={{ borderBottomStyle: "solid" }}
          >
            <AppSwitch
              label={"ASSIGN ALL"}
              checked={areAllPermissionsTogglesChecked(selectedPermissions)}
              onChange={(e) => {
                handleAllTogglesChange(e);
              }}
            />
          </StyledRolesPermissionsToggleRow>
          <StyledRolesTogglesContainer>
            {Object.values({ ...selectedPermissions }).map(
              (permission, index) => {
                return (
                  <React.Fragment key={permission.label}>
                    <StyledRolesPermissionsToggleRow>
                      <Tooltip
                        title={getTooltipText(permission.value)}
                        placement="right"
                      >
                        <div>
                          <AppSwitch
                            label={permission.label}
                            checked={Boolean(
                              selectedPermissions &&
                                permission &&
                                selectedPermissions[permission.value]?.isChecked
                            )}
                            onChange={(e) => {
                              handleToggleChange(permission.value, e);
                            }}
                            isDisabled={isOptionDisabled(permission.value)}
                          />
                        </div>
                      </Tooltip>
                    </StyledRolesPermissionsToggleRow>
                  </React.Fragment>
                );
              }
            )}
          </StyledRolesTogglesContainer>
        </StyledInputsContainer>

        <StyledAppModalFooterButtonContainer>
          <AppButton onClick={handlePublish} isLoading={isLoading}>
            PUBLISH
          </AppButton>
        </StyledAppModalFooterButtonContainer>
      </StyleAppModalSingleStepContainer>

      <AppSideMenuContainer hideInMobile={true}>
        <SideInfoPermissionsInRole />
      </AppSideMenuContainer>
    </div>
  );
};
