import styled, { useTheme } from "styled-components";
import { APP_COMMON_COLORS } from "../../../../../../providers/AppThemeProvider";
import { useEffect, useState } from "react";
import { COLLECTIONS_TYPE } from "../../../../../../constants/firebase.constants";
import { getFirebaseDataByColDocColKeyVal } from "../../../../../../hooks/firebase/getFirebaseDataByColDocColKeyVal";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../../../redux/client/clientSlice";
import { useAtom } from "jotai";
import { MEMBERS_ATOMS } from "../../../../MembersPage.Atoms";
import { WhereFilter } from "../../../../../../hooks/firebase/firebase.types";
import { TEMPLATE_DATA } from "../../../../../../types/templates/templates.types";
import { Circle } from "@mui/icons-material";

export const StyledTitle = styled.div({
  color: APP_COMMON_COLORS.dark[600],
  fontWeight: 400,
  fontSize: 14,
  marginBottom: 12,
});

const StyledContainer = styled.div({
  display: "flex",
  gap: 20,
  alignItems: "center",
  marginBottom: 5,
});

export const GroupsSideMenuTemplates = () => {
  const client = useSelector(getClientSelector);
  const [currentActiveRole, setCurrentActiveRole] = useAtom(
    MEMBERS_ATOMS.currentActiveRole
  );

  const [availablePostTemplates, setAvailablePostTemplates] = useState<
    TEMPLATE_DATA[]
  >([]);

  const theme = useTheme();

  useEffect(() => {
    getFirebaseDataByColDocColKeyVal(
      COLLECTIONS_TYPE.templates,
      String(client.clientId),
      COLLECTIONS_TYPE.clientTemplates,
      "usableBy",
      currentActiveRole,
      { opStr: WhereFilter["array-contains"] }
    ).then((data) => {
      const postsTemplates: TEMPLATE_DATA[] = Object.values(data || {});
      if (postsTemplates.length > 0) {
        setAvailablePostTemplates(postsTemplates);
      } else {
        setAvailablePostTemplates([]);
      }
    });
  }, [currentActiveRole]);

  return (
    <div>
      <StyledTitle>Available Post Templates</StyledTitle>
      {availablePostTemplates?.length > 0 ? (
        availablePostTemplates.map((template) => (
          <StyledContainer key={template.templateId}>
            <Circle
              style={{
                color: theme.colorPrimary,
                fontSize: 10,
              }}
            >
              {template.templateName}
            </Circle>
            <div style={{ color: APP_COMMON_COLORS.dark[600] }}>
              {template.templateName}
            </div>
          </StyledContainer>
        ))
      ) : (
        <div style={{ color: APP_COMMON_COLORS.dark[600], fontSize: 14 }}>
          No templates available
        </div>
      )}
    </div>
  );
};
