import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { _fs } from "../../services/firebase/config";

/** get COLLECTION
 * note: you can use this function inside useEffect
 */
export const getFirebaseDataCollectionDoc = async (
  collection: COLLECTIONS_TYPE,
  doc: string
) => {
  return await _fs
    .collection(collection)
    .doc(doc)
    .get()
    .then((data) => data?.data())
    .catch((error) => {
      return { status: "nok", error };
    });
};
