import { Checkbox } from "@mui/material";
import { CheckBoxIconNotChecked } from "../AppBurgerMenu/icons/CheckBoxIconNotChecked";
import { CheckBoxIcon } from "../AppBurgerMenu/icons/CheckBoxIcon";
import {
  StyledAppCheckboxesRowsContainer,
  StyledAppCheckboxesTitle,
  StyledAppCheckboxRowLabel,
  StyledCheckboxRow,
} from "./AppCheckboxesRows.styles";
import { useEffect, useState } from "react";
import { CSSObject } from "styled-components";

export interface CheckboxData {
  label: string;
  isChecked: boolean;
  isDisabled?: boolean;
}
interface AppCheckboxesRowsProps {
  title?: string;
  checkboxes: CheckboxData[];
  onChangeCheckedSubGroups: (checkedSubGroups: CheckboxData[]) => void;
  isDisabled?: boolean;
  noOptionsMessage?: string | React.ReactNode;
  containerStyles?: CSSObject;
}

export const AppCheckboxesRows = (props: AppCheckboxesRowsProps) => {
  const [checkboxes, setCheckboxes] = useState<CheckboxData[]>(
    props.checkboxes
  );

  useEffect(() => {
    setCheckboxes(props.checkboxes);
  }, [props.checkboxes?.length]);

  return (
    <StyledAppCheckboxesRowsContainer style={{ ...props.containerStyles }}>
      {props.title && (
        <StyledAppCheckboxesTitle>{props.title}</StyledAppCheckboxesTitle>
      )}
      {checkboxes?.length > 0 ? (
        <div style={{ height: "80%", overflow: "auto" }}>
          {checkboxes.map((checkbox, index) => (
            <StyledCheckboxRow key={index}>
              <Checkbox
                style={{
                  padding: 0,
                  paddingInline: 5,
                }}
                checked={checkbox.isChecked}
                icon={<CheckBoxIconNotChecked width={20} height={20} />}
                checkedIcon={<CheckBoxIcon width={20} height={20} />}
                disableRipple
                disabled={Boolean(checkbox.isDisabled)}
                onChange={(e) => {
                  if (checkbox.isDisabled) return;

                  const newCheckboxes = [...checkboxes];
                  newCheckboxes[index] = {
                    ...newCheckboxes[index],
                    isChecked: e.target.checked,
                  };

                  setCheckboxes(newCheckboxes);
                  props.onChangeCheckedSubGroups(newCheckboxes);
                }}
              />
              <StyledAppCheckboxRowLabel>
                {checkbox.label}
              </StyledAppCheckboxRowLabel>
            </StyledCheckboxRow>
          ))}
        </div>
      ) : (
        <div>
          {props.noOptionsMessage ? props.noOptionsMessage : "No options"}
        </div>
      )}
    </StyledAppCheckboxesRowsContainer>
  );
};
