import { atom } from "jotai";
import {
  EVENT_CUSTOM_FIELD,
  EVENT_CUSTOM_FIELDS_TYPE,
  TEMPLATE_TYPE,
} from "../../../types/templates/templates.types";
import { EventAdditionalFieldsStepValues } from "./EventAdditionalFieldsStep/EventAdditionalFieldsStep";
import { PERFORMANCE_POST_FIELDS } from "./PerformancePostFields/PerformancePostFields";
import {
  FORM_FIELD,
  FORM_FIELDS_DISPLAY_STATES,
  FORM_FIELDS_TYPES,
} from "./FormPostFieldsV2/FormPostFields.types";

export const NAME_TYPE_STEP = "Name & type";

export const TEMPLATE_STEPPER_TITLES: any = {
  [TEMPLATE_TYPE.event]: [NAME_TYPE_STEP, "Additional fields"],
  [TEMPLATE_TYPE.performancePost]: [NAME_TYPE_STEP, "Permissions", "Fields"],
  [TEMPLATE_TYPE.quickPost]: [NAME_TYPE_STEP, "Permissions"],
  [TEMPLATE_TYPE.formPost]: [NAME_TYPE_STEP, "Permissions", "Fields"],
};

export const TEMPLATES_ATOMS = {
  templateName: atom(""),
  templateType: atom<TEMPLATE_TYPE | undefined>(undefined),

  eventAdditionalFields: atom<EventAdditionalFieldsStepValues>({}),
  eventCustomFields: atom<EVENT_CUSTOM_FIELD[]>([]),
  currentModalStep: atom(0),
  templateStepperTitles: atom<string[] | any>([NAME_TYPE_STEP]),
  additionalFieldsComponent: atom<"DEFINED_FIELDS" | "CUSTOM_FIELDS">(
    "DEFINED_FIELDS"
  ),
  performancePostFields: atom<PERFORMANCE_POST_FIELDS[] | []>([]),
  customFieldsType: atom<EVENT_CUSTOM_FIELDS_TYPE | undefined>(undefined),
  isPublished: atom<boolean>(false),
  isModalOpen: atom<boolean>(false),
  isEditMode: atom<boolean>(false),
  templateId: atom(""),
  formPostAdditionalFields: atom<FORM_FIELD[]>([]),
  customFormFieldSelectType: atom(""),
  usableBy: atom<string[]>([]),
  viewableBy: atom<string[]>([]),
  roles: atom<{}>({}),
};

export const FORM_FIELD_ATOMS = {
  displayState: atom<FORM_FIELDS_DISPLAY_STATES>(
    FORM_FIELDS_DISPLAY_STATES.FIELDS_ROWS
  ),
  fieldSelectType: atom<FORM_FIELDS_TYPES | undefined>(undefined),
  editingFieldIndex: atom<number | undefined>(undefined),
};
