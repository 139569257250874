import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";

export const StyledAppCheckboxesRowsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

export const StyledAppCheckboxesTitle = styled.div({
  fontSize: 18,
  fontWeight: 500,
});

export const StyledCheckboxRow = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 10,
  paddingBlock: 5,
  borderBottom: `1px dashed ${APP_COMMON_COLORS.dark[100]}`,
});

export const StyledAppCheckboxRowLabel = styled.div({});
